export const directorConstants = {
    GET_SCHEDULE: "GET_SCHEDULE",
    APPROVAL_STATUS_CHANGE: "APPROVAL_STATUS_CHANGE",
    GET_DOCTOR: "GET_DOCTOR",
    GET_CUSTOMER: "GET_CUSTOMER",
    GET_ASSIGNED_TO: "GET_ASSIGNED_TO",
    GET_UID_APPROVAL: "GET_UID_APPROVAL",
    GET_PRODUCTS_ALL: "GET_PRODUCTS_ALL",
    GET_PRODUCTS_NEW: "GET_PRODUCTS_NEW",
    GET_DISTRIBUTION_CENTER: "GET_DISTRIBUTION_CENTER",
    GET_DEPARTMENT_HEAD: "GET_DEPARTMENT_HEAD",
    GET_ASSIGNED_TO_RSM: "GET_ASSIGNED_TO_RSM",
    GET_ASSIGNED_TO_AM: "GET_ASSIGNED_TO_AM",
    GET_ASSIGNED_TO_MPO: "GET_ASSIGNED_TO_MPO",
    GET_SCHEDULE_SINGLE_UID: "GET_SCHEDULE_SINGLE_UID",
    GET_SINGLE_PRODUCT_OBJ: "GET_SINGLE_PRODUCT_OBJ",
  };
  
import React, { useState } from "react";
import { CompanyLogos } from "../components";
import BannerWithText from "../components/ReusableComponents/BannerImgComponents/BannerImgComponents";
import { FlipCard } from "../components";
import SingleCard from "../components/HomeComponents/FlipCard/SingleCard";
import ReactPaginate from 'react-paginate';
import { ByTherapeutic } from "../components/HomeComponents/ProductsData/productbytheraputic";

const Products_bygeneric = (props) => {
  const LinksBan = [
    {
      subLinkName: "Home",
      subDash: "/",
      subLink: "/",
    },
    {
      subLinkName: "Products",
      subDash: "/",
      subLink: "/products",
    },
  ];

  const [obj, setObj] = useState(ByTherapeutic);

  const filteredtype = (type) => {
    if (type === "All") {
      setObj(ByTherapeutic);
      setPageNumber(0);
    } else if (type === "Syrup") {
      const filterd = ByTherapeutic.filter((category) => category.type === "Syrup");
      setObj(filterd);
      setPageNumber(0);
    } else if (type === "Tablet") {
      const filterd = ByTherapeutic.filter(
        (category) => category.type === "Tablet"
      );
      setObj(filterd);
      setPageNumber(0);
    } else if (type === "Capsule") {
      const filterd = ByTherapeutic.filter(
        (category) => category.type === "Capsule"
      );
      setObj(filterd);
      setPageNumber(0);
    } else if (type === "Injectables") {
      const filterd = ByTherapeutic.filter(
        (category) => category.type === "Injectables"
      );
      setObj(filterd);
      setPageNumber(0);
    }
  };

  const [selected, setSelected] = useState('5');


  const [pageNumber, setPageNumber] = useState(0);
  const perPage = selected;

  const pageVisited = pageNumber * perPage;
  const displayUsers = obj
    .slice(pageVisited, pageVisited + perPage)
    .map((ob, index) => (
      <React.Fragment key={ob.id}>
        <>
          <div className="col-lg-4 d-none d-lg-block d-md-none">
            <FlipCard card={ob} {...props}/>
          </div>
          <div className="col-lg-4 d-lg-none d-sm-block col-md-6">
            <SingleCard card={ob} {...props}/>
          </div>
        </>
      </React.Fragment>
    ));

  const pageCount = Math.ceil(obj.length / perPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  return (
    <div>
      <BannerWithText
        heading={"By Generic Name"}
        subHeading={`BY GENERIC NAME`}
        LinksBan={LinksBan}
        height={""}
        // backposit={'center right'}
        backimg={`linear-gradient(rgba(20, 20, 19, 0.8), rgba(20, 20, 19, 0.6)),url()`}
        backgroundSize={""}
        conmarpad={""}
        fontsize={"45px"}
      />

      <div className="container mt-5">
        <div className="row">
          <div className="col-sm-12 col-lg-3">

            <div className="productFilterContent">

            <div className="productFilterContent1">
              <h3 className="filterHeading" style={{ fontWeight: "600" }}>
                Product Categories
              </h3>
              <form id="category-radio-btn">
                <input
                  className="me-2"
                  type="radio"
                  id="all"
                  name="product-category"
                  value="1"
                  defaultChecked
                  onChange={() => filteredtype("All")}
                />
                <label htmlFor="all">All</label>
                <br />
                <input
                  className="me-2"
                  type="radio"
                  id="injectables"
                  name="product-category"
                  value="2"
                  onChange={() => filteredtype("Injectables")}
                />
                <label htmlFor="injectables">Injectables</label>
                <br />
                <input
                  className="me-2"
                  type="radio"
                  id="syrup"
                  name="product-category"
                  value="3"
                  onChange={() => filteredtype("Syrup")}
                />
                <label htmlFor="syrup">Syrup</label>
                <br />
                <input
                  className="me-2"
                  type="radio"
                  id="tablet"
                  name="product-category"
                  value="4"
                  onChange={() => filteredtype("Tablet")}
                />
                <label htmlFor="tablet">Tablet</label>
                <br />
                <input
                  className="me-2"
                  type="radio"
                  id="capsule"
                  name="product-category"
                  value="5"
                  onChange={() => filteredtype("Capsule")}
                />
                <label htmlFor="capsule">Capsule</label>
                <br />
              </form>
            </div>
            {/* <div className="">
            <label className="my-2" style={{fontSize:'18px',fontWeight:'500',color:'#565656'}}>No of Products:</label>
                <select
            className="form-control form-select"
            name="sm"
            onChange={(e) => {
              setSelected(e.target.value);
             
            }}
            value={selected}
          >
            <option selected>9</option>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
            
          </select>
            </div>
             */}
          

            </div>

          </div>

          <div className="col-sm-12 col-lg-9 mt-0">


          
          <div className="row mb-3">
              <div className="col-lg-6">
                Select Items Per Page
                <div class="btn-group ms-3" role="group" aria-label="First group">
                <button className={selected === '5' ? 'btn  btn-secondary':'btn btn-light  '} style={{width:"70px"}} onClick={(e)=> setSelected(e.target.value)} value="5">5</button>
                <button className={selected === '10' ? 'btn btn-secondary':'btn btn-light  '} style={{width:"70px"}} onClick={(e)=> setSelected(e.target.value)} value="10">10</button>
                <button className={selected === '20' ? 'btn btn-secondary':'btn btn-light  '} style={{width:"70px"}} onClick={(e)=> setSelected(e.target.value)} value="20">20</button>
                <button className={selected === '50' ? 'btn btn-secondary':'btn btn-light  '} style={{width:"70px"}} onClick={(e)=> setSelected(e.target.value)} value="50">50</button>
                <button className={selected === '100' ?'btn btn-secondary':'btn btn-light  '} style={{width:"70px"}} onClick={(e)=> setSelected(e.target.value)} value="100">100</button>
                
                </div> 
              </div>
              <div className="col-lg-6 d-flex justify-content-end">
              Item Per Page  : &nbsp; <span style={{fontWeight:'600',color:'#0066b3'}}>{selected}</span>  
                </div> 
            </div>

            <div className="row">
            {displayUsers}
              <ReactPaginate
                previousLabel={<i  style={{fontSize:'20px' ,color: '#0066b3'}} className="fa fa-arrow-left"></i>}
                nextLabel={<i  style={{fontSize:'20px' ,color: '#0066b3'}} className="fa fa-arrow-right"></i>}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={"paginationcustom"}
                subContainerClassName={"pages paginationcustom"}
                activeClassName={"active"}
                forcePage={pageNumber}
                />
    

  



            </div>
          </div>
        </div>
      </div>

      <CompanyLogos />
    </div>
  );
};

export default Products_bygeneric;

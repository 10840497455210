import acedolfr from '../../../Statics/assets/first-time-launching/Acedol-Tabletfr.jpg';
import acedolbk from '../../../Statics/assets/first-time-launching/ban-Acedol-Tabletbk.jpg';

import acubis25fr from '../../../Statics/assets/first-time-launching/Acubis-2.5-eng.jpg';
import acubis25bk from '../../../Statics/assets/first-time-launching/Acubis-2.5-ban.jpg';

import conzi100fr from '../../../Statics/assets/first-time-launching/Conzi-100-ml-eng.jpg';
import conzi100bk from '../../../Statics/assets/first-time-launching/Conzi-100-ml-ban.jpg';

import dapazin5fr from '../../../Statics/assets/first-time-launching/Dapazin-5-eng.jpg';
import dapazin5bk from '../../../Statics/assets/first-time-launching/Dapazin-5_Ban.jpg';

import dapazin10fr from '../../../Statics/assets/first-time-launching/Dapazin-10-eng.jpg';
import dapazin10bk from '../../../Statics/assets/first-time-launching/Dapazin-10-ban.jpg';

import dopagut60fr from '../../../Statics/assets/first-time-launching/Dopagut-60-ml-eng.jpg';
import dopagut60bk from '../../../Statics/assets/first-time-launching/Dopagut-60-ml-ban.jpg';

import relikof25fr from '../../../Statics/assets/first-time-launching/Relikof-2.5_Eng.jpg';
import relikof25bk from '../../../Statics/assets/first-time-launching/Relikof-2.5_Eng.jpg';

import relikofkidzfr from '../../../Statics/assets/first-time-launching/Relikof-Kidz-eng.jpg';
import relikofkidzbk from '../../../Statics/assets/first-time-launching/Relikof-Kidz-ban.jpg';

import unilorfr from '../../../Statics/assets/first-time-launching/Unilor-Tablet-eng.jpg';
import unilorbk from '../../../Statics/assets/first-time-launching/Unilor-Tablet-ban.jpg';

export const FirstTime = [
  // {
  //   id: 0,
  //   title: "Acedol  Tablet",
  //   text1: "NSAID",
  //   text2: "Aceclofenac 100 mg",
  //   img: [
  //     {
  //       id: 0,
  //       imgf: acedolfr,
  //       imge: acedolbk,
  //     },
  //   ],
  //   innerMainHeading: "Acedol",
  //   innerComposition: "Aceclofenac BP",
  //   innerdata:[
  //     {
  //       innerHead: "PRESENTATION",
  //       innerData: ``,
  //       istable: false,
  //       table: {},
  //       islist: true,
  //       listData:[
  //         "Acedol® Tablet: Each film coated tablet contains- Aceclofenac BP 100 mg.",
  //         "Acedol® SR Tablet: Each sustained release tablet contains- Aceclofenac BP 200 mg.",
  //       ]
  //     },
  //     {
  //       innerHead: "PHARMACOLOGY",
  //       innerData: "Aceclofenac is a non-steroidal agent with marked anti-inflammatory and analgesic properties. It is a potent inhibitor of the enzyme cycloxygenase which is involved in the production of prostaglandin.",
  //       istable: false,
  //       table: {},
  //       islist: false,
  //       listData:[]
  //     },
  //     {
  //       innerHead: "INDICATIONS",
  //       innerData: "For the relief of pain and inflammation in osteoarthritis, rheumatoid arthritis and ankylosing spondylitis.",
  //       istable: false,
  //       table: {},
  //       islist: false,
  //       listData:[]
  //     }
  //   ],
  //   innerdata1:[
  //     {
  //       innerHead: "DOSAGE AND ADMINISTRATION",
  //       innerData: "Acedol® Tablet: Adults: The recommended dose is 100 mg, twice daily. Children: There are no clinical data on the use of Aceclofenac in children. Elderly: The pharmacokinetics of Aceclofenac is not altered in elderly patients, therefore it is not considered necessary to modify the dose or dose frequency. Acedol® SR Tablet:The recommended dose is 200 mg, once daily.",
  //       istable: false,
  //       table: {},
  //       islist: false,
  //       listData:[]
  //     },
  //     {
  //       innerHead: "CONTRAINDICATION AND PRECAUTION",
  //       innerData: "Aceclofenac should not be administered to patients with active or suspected peptic ulcer or gastro-intestinal bleeding. It should not be given to patients with moderate to severe renal impairment. Close medical surveillance is also imperative in patients suffering from severe impairment of hepatic function. It should not be prescribed during pregnancy, unless there are compelling reasons for doing so. The lowest effective dosage should be used. Aceclofenac should not be administered to patients previously sensitive to Aceclofenac or in whom aspirin or NSAIDs precipitate attacks of asthma, acute rhinitis or urticaria or who are hypersensitive to these drugs.",
  //       istable: false,
  //       table: {},
  //       islist: false,
  //       listData:[]
  //     },
  //     {
  //       innerHead: "SIDE-EFFECT",
  //       innerData: "The majority of side-effects observed have been reversible and of a minor nature and include gastro-intestinal disorders (dyspepsia, abdominal pain, nausea and diarrhea) and occasional occurrence of dizziness. Dermatological complaints including pruritus and rash and abnormal hepatic enzyme levels and raised serum creatinine have occasionally been reported.",
  //       istable: false,
  //       table: {},
  //       islist: false,
  //       listData:[]
  //     },
  //     {
  //       innerHead: "OVER DOSAGE",
  //       innerData: "There are no human data available on the consequences of Aceclofenac over dosage. If over dosage is observed, therapeutic measures should be taken according to symptoms; supportive and symptomatic treatment should be given for complications such as hypotension, gastro-intestinal irritation, respiratory depression, and convulsions.",
  //       istable: false,
  //       table: {},
  //       islist: false,
  //       listData:[]
  //     },
  //     {
  //       innerHead: "DRUG INTERACTIONS",
  //       innerData: "Lithium and Digoxin: Aceclofenac, like many NSAIDs may increase plasma concentrations of lithium and Digoxin. Diuretics: Aceclofenac, like other NSAIDs, may interact the activity of diuretics. Anticoagulants: Like other NSAIDs, Aceclofenac may enhance the activity of anticoagulant. Close monitoring of patients on combined anticoagulants and Aceclofenac therapy should be undertaken. Methotrexate: Caution should be exercised if NSAIDs and Methotrexate are administered within 24 hours of each other, since NSAIDs may increase Methotrexate plasma levels, resulting in increased toxicity",
  //       istable: false,
  //       table: {},
  //       islist: false,
  //       listData:[]
  //     },
  //     {
  //       innerHead: "USE IN PREGNANCY AND LACTATIO",
  //       innerData: "The use of Aceclofenac should be avoided in pregnancy and lactation unless the potential benefits to the mother outweigh the possible risks to the fetus.",
  //       istable: false,
  //       table: {},
  //       islist: false,
  //       listData:[]
  //     },
  //     {
  //       innerHead: "STORAGE",
  //       innerData: "Store in a cool (Below 30⁰ C. Temp.), dry place and protect from light. Keep all medicines out of the reach of children.",
  //       istable: false,
  //       table: {},
  //       islist: false,
  //       listData:[]
  //     },
  //     {
  //       innerHead: "COMMERCIAL PACK",
  //       innerData: ``,
  //       istable: false,
  //       table: {},
  //       islist: true,
  //       listData: [
  //       "Acedol® Tablet: Each box containing 10x10’s tablet, in Alu-PVC blister pack.",
  //       "Acedol® SR Tablet: Each box containing 5x10’s tablet, in Alu Opaque PVDC blister pack.",
  //     ],
  //     },
  //   ],
  //   btn: "Read More",
  //   backpagename: "First Time Launching",
  //   backpagelink: "/products_firsttime",
  //   btnlink: "/acedolcopy-tablet",
  //   type: 'Tablet'
  // },


  // {
  //   id: 1,
  //   title: "Acubis 2.5  Tablet",
  //   text1: "cardiovascular",
  //   text2: "bisoprolol 2.5 mg",
  //   img: [
  //     {
  //       id: 1,
  //       imgf: acubis25fr,
  //       imge: acubis25bk,
  //     },
  //   ],
  //   innerMainHeading: "Acubis",
  //     innerComposition: "Bisoprolol Fumarate BP",
  //      innerdata: [
  //       {
  //         innerHead: "COMPOSITION",
  //         innerData: `Acubis®2.5 Tablet: Each film coated tablet contains- Bisoprolol Fumarate BP 2.5 mg.
  //             Acubis®5 Tablet: Each film coated tablet contains- Bisoprolol Fumarate BP 5 mg.`,
  //             istable: false,
  //         table: {},
  //         islist: false,
  //         listData:[]
  //       },
  //       {
  //         innerHead: "PHARMACOLOGY",
  //         innerData: `Bisoprolol is the most selective beta-1 blocker. It displays
  //             highest level of affinity for the beta-1 receptor than any other
  //             beta-blocker available up to now. Selectively blocks beta-1
  //             adrenergic receptor in the heart and vascular smooth muscle and
  //             reduces heart rate and cardiac output resulting in decrease of
  //             arterial hypertension. Lipid metabolism can be adversely affected
  //             by beta-blockers, in patients with non-beta 1 selective
  //             beta-blocker, but Bisoprolol does not cause any change in the
  //             cholesterol fraction including the cardioprotective
  //             HDL-cholesterol, in long-term therapy.`,
  //             istable: false,
  //         table: {},
  //         islist: false,
  //         listData:[]
  //       },
  //       {
  //         innerHead: "INDICATIONS",
  //         innerData: `Bisoprolol is indicated in the treatment of hypertension, angina and heart failure. It may be used alone or in combination with other antihypertensive agents.`,
  //         istable: false,
  //         table: {},
  //         islist: false,
  //         listData:[]
  //       }
  //     ],
  //     innerdata1: [
  //       {
  //         innerHead: "INDICATIONS",
  //         innerData: `Bisoprolol is indicated in the treatment of hypertension, angina
  //         and heart failure. It may be used alone or in combination with
  //         other antihypertensive agents.`,
  //         istable: false,
  //         table: {},
  //         islist: false,
  //         listData:[]
  //       },
  //       {
  //         innerHead: "DOSAGE & ADMINISTRATION",
  //         innerData: `Hypertension and angina: Usually 5-10 mg once daily, maximum 20 mg
  //         daily, Moderate to severe heart failure: Initially 1.25 mg once
  //         daily (in the morning) for 1 week then, if well tolerated,
  //         increased to 2.5 mg once daily for 1 week, then 3.75 mg once daily
  //         for 1 week, then 5 mg once daily for 4 weeks, then 7.5 mg once
  //         daily for 4 weeks, then 10 mg once daily; maximum 10 mg daily.`,
  //         istable: false,
  //         table: {},
  //         islist: false,
  //         listData:[]
  //       },
  //       {
  //         innerHead: "SIDE-EFFECTS",
  //         innerData: `Fatigue, dizziness, headache, disturbances of the gut such as
  //         nausea, vomiting, diarrhoea, constipation or abdominal pain, cold
  //         or numb extremities, e.g. hands and feet, muscle weakness or
  //         cramps, slower than normal heart beat (bradycardia), worsening of
  //         heart failure, sleep disturbance, depression, breathing
  //         difficulties due to a narrowing of the airways (bronchospasm) in
  //         people with asthma or COPD.`,
  //         istable: false,
  //         table: {},
  //         islist: false,
  //         listData:[]
  //       },
  //       {
  //         innerHead: "Contraindications",
  //         innerData: `Bisoprolol is contraindicated in patients with cardiogenic shock,
  //         overt cardiac failure, second or third degree AV block and marked
  //         sinus bradycardia.`,
  //         istable: false,
  //         table: {},
  //         islist: false,
  //         listData:[]
  //       },
  //       {
  //         innerHead: "USE IN PREGNANCY AND LACTATION",
  //         innerData: `Pregnancy Category C. There are no adequate and well-controlled
  //         studies in pregnant women. Bisoprolol should be used during
  //         pregnancy only if the potential benefit justifies the potential
  //         risk to the fetus. Lactating Mothers: Small amounts of Bisoprolol
  //         2% of the dose have been detected in the milk of lactating rats.
  //         It is not known whether this drug is excreted in human milk.
  //         Because many drugs are excreted in human milk caution should be
  //         exercised when Bisoprolol is administered to lactating women.`,
  //         istable: false,
  //         table: {},
  //         islist: false,
  //         listData:[]
  //       },
  //       {
  //         innerHead: "DRUG INTERACTION",
  //         innerData: `Bisoprolol should not be combined with other beta-blocking agents.`,
  //         istable: false,
  //         table: {},
  //         islist: false,
  //         listData:[]
  //       },
  //       {
  //         innerHead: "Overdosage",
  //         innerData: `The most common signs expected with over dosage of a beta-blocker
  //         are bradycardia, hypotension, congestive heart failure,
  //         bronchospasm, and hypoglycemia. To date, a few cases of overdose
  //         (maximum: 2000 mg) with Bisoprolol have been reported. Bradycardia
  //         and/or hypotension were noted. Sympathomimetic agents were given
  //         in some cases, and all patients recovered. In general, if overdose
  //         occurs, Bisoprolol therapy should be stopped and supportive and
  //         symptomatic treatment should be provided. Limited data suggest
  //         that Bisoprolol is not dialyzable. Based on the expected
  //         pharmacologic actions and recommendations for other beta-blockers,
  //         the following general measures should be considered when
  //         clinically warranted.
  //         `,
  //         istable: false,
  //         table: {},
  //         islist: false,
  //         listData:[]
  //       },
  //       {
  //         innerHead: "PRECAUTIONS",
  //         innerData: `Monitoring of renal, hepatic, and hematopoietic function should be
  //         performed at regular intervals during long-term treatment with
  //         Bisoprolol.
  //         `,
  //         istable: false,
  //         table: {},
  //         islist: false,
  //         listData:[]
  //       },
  //       {
  //         innerHead: "STORAGE",
  //         innerData: `Monitoring of renal, hepatic, and hematopoietic function should be
  //         performed at regular intervals during long-term treatment with
  //         Bisoprolol.
  //         `,
  //         istable: false,
  //         table: {},
  //         islist: false,
  //         listData:[]
  //       },
  //       {
  //         innerHead: "PRECAUTIONS",
  //         innerData: `Store in a cool (Below 30⁰ C Temp.) and dry place, away from
  //         light. Keep out of the reach of children.
  //         `,
  //         istable: false,
  //         table: {},
  //         islist: false,
  //         listData:[]
  //       },
  //       {
  //         innerHead: "Commercial Pack",
  //         innerData: `Acubis®2.5 Tablet : Each box contains 3x10’s tablets in blister
  //         strip. Acubis®5 Tablet : Each box contains 3x10’s tablets in
  //         blister strip.
  //         `,
  //         istable: false,
  //         table: {},
  //         islist: false,
  //         listData:[]
  //       },
  //     ],
  //   btn: "Read More",
  //   backpagename: "First Time Launching",
  //   backpagelink: "/products_firsttime",
  //   btnlink: "#",
  //   type: 'Tablet'
  // },

  // {
  //   id: 2,
  //   title: "Conzi 100 ml Syrup",
  //   text1: "Vitamin & Minerals",
  //   text2: "Zinc SUlphate 10 mg/5 ml",
  //   img: [
  //     {
  //       id: 2,
  //       imgf: conzi100fr,
  //       imge: conzi100bk,
  //     },
  //   ],
  //   innerMainHeading: "Conzi",
  //   innerComposition: "Zinc Sulphate",
  //   innerdata:[
  //     {
  //       innerHead: "COMPOSITION",
  //       innerData: "Conzi® Syrup: Each 5 ml contains- Zinc Sulphate Monohydrate USP equivalent to 10 mg elemental Zinc.",
  //       istable: false,
  //       table: {},
  //       islist: false,
  //       listData:[
  //         "",
  //         "",
  //       ]
  //     },
  //     {
  //       innerHead: "PHARMACOLOGY",
  //       innerData: "Conzi® is the preparations of zinc which is an essential trace element and involved in a number of body enzyme systems. The body needs zinc for normal growth and health. Zinc is vital for growth and development, sexual maturation and reproduction, dark vision adaptation, olfactory and gustatory activity, insulin storage and release. Zinc deficiency may lead to impaired immune function, delayed wound healing, a decrease sense of taste and smell, a reduced ability to fight infections, poor night vision, increased risk of abortion, alopecia, mental lethargy, skin changes and poor development of reproductive organs.",
  //       istable: false,
  //       table: {},
  //       islist: false,
  //       listData:[
  //         "",
  //         "",
  //       ]
  //     },
  //     {
  //       innerHead: "INDICATION",
  //       innerData: "Conzi® is indicated in zinc deficiency and/or zinc losing conditions. It is indicated in the following conditions - Recurrent respiratory tract infections, Diarrhoea, Loss of appetite, Severe growth retardation, Deformed bone formation, Impaired immunological response, Acrodermatitsenteropathica, Parakeratatic skin lesions, Defective and delayed wound healing, Anaemia, Night blindness & Mental disturbances.",
  //       istable: false,
  //       table: {},
  //       islist: false,
  //       listData:[
  //         "",
  //         "",
  //       ]
  //     },
      
  //   ],
  //   innerdata1:[
  //     {
  //       innerHead: "DOSAGE & ADMINISTRATION",
  //       innerData: "Zinc supplements are most effective if it is taken at least 1 hour before or 2 hours after meal.",
  //       istable: false,
  //       table: {},
  //       islist: true,
  //       listData:[
  //         "Zinc supplements are most effective if it is taken at least 1 hour before or 2 hours after meal.",
  //         "Child between 10 - 30 kg: 10 ml (2 teaspoonful) 1-3 times daily.",
  //         "Adults and child over 30 kg: 20 ml (4 teaspoonful) 1-3 times daily.",
  //       ]
  //     },
  //     {
  //       innerHead: "SIDE-EFFECTS",
  //       innerData: "Zinc may cause nausea, vomiting, diarrhoea, stomach upset, heartburn and gastritis.",
  //       istable: false,
  //       table: {},
  //       islist: false,
  //       listData:[
  //         "",
  //         "",
  //       ]
  //     },
  //     {
  //       innerHead: "CONTRAINDICATION",
  //       innerData: "It is contraindicated in those who are hypersensitive to any component of the ingredient of this zinc containing supplement.",
  //       istable: false,
  //       table: {},
  //       islist: false,
  //       listData:[
  //         "",
  //         "",
  //       ]
  //     },
  //     {
  //       innerHead: "USE IN PREGNANCY & LACTATION",
  //       innerData: "Zinc is used during pregnancy and lactation at a dose of 20 mg per day. Zinc crosses the placenta and is present in breast milk.",
  //       istable: false,
  //       table: {},
  //       islist: false,
  //       listData:[
  //         "",
  //         "",
  //       ]
  //     },
  //     {
  //       innerHead: "PRECAUTION",
  //       innerData: "In acute renal failure zinc accumulation may occur; so doses adjustment is needed.",
  //       istable: false,
  //       table: {},
  //       islist: false,
  //       listData:[
  //         "",
  //         "",
  //       ]
  //     },
  //     {
  //       innerHead: "DRUG INTERACTION",
  //       innerData: "Concomitant intake of a tetracycline and zinc may decrease the absorption of both the tetracycline and zinc. Similarly concomitant administration of zinc and quinolone may also decrease the absorption of both. Concomitant intake of penicillamine and zinc may depress absorption of zinc.",
  //       istable: false,
  //       table: {},
  //       islist: false,
  //       listData:[
  //         "",
  //         "",
  //       ]
  //     },
  //     {
  //       innerHead: "OVER DOSAGE",
  //       innerData: "Symptoms of overdose may include severe nausea, vomiting, dizziness, fainting and shortness of breath. If overdose is suspected, then contact with local poison control center or emergency room may be needed.",
  //       istable: false,
  //       table: {},
  //       islist: false,
  //       listData:[
  //         "",
  //         "",
  //       ]
  //     },
  //     {
  //       innerHead: "STORAGE",
  //       innerData: "Store in a cool (Below 30⁰ C. Temp.), dry place and protect from light. Keep all medicines out of the reach of children.",
  //       istable: false,
  //       table: {},
  //       islist: false,
  //       listData:[
  //         "",
  //         "",
  //       ]
  //     },
  //     {
  //       innerHead: "COMMERCIAL PACK:",
  //       innerData: "Conzi® Syrup: Bottle containing 100 ml syrup with a measuring cup.",
  //       istable: false,
  //       table: {},
  //       islist: false,
  //       listData:[
  //         "",
  //         "",
  //       ]
  //     },
      
  //   ],
  //   btn: "Read More",
  //   backpagename: "First Time Launching",
  //   backpagelink: "/products_firsttime",
  //   btnlink: "#",
  //   type: 'Syrup'
  // },


  // Dapzin Cut
  {
    id: 3,
    title: "Dapazin 5 Tablet",
    text1: "Vitamin & Minerals",
    text2: "Dapagliflozin INN 5 mg",
    img: [
      {
        id: 3,
        imgf: dapazin5fr,
        imge: dapazin5bk,
      },
    ],
     innerMainHeading: "Dapazin",
      innerComposition: "Dapagliflozin INN",
      innerdata:[
        {
          innerHead: "PRESENTATION",
          innerData: ``,
          istable: false,
          table: {},
          islist: true,
          listData:[
            "Dapazin® 5 tablet: Each film coated tablet contains Dapagliflozin INN 5 mg.",
            "Dapazin® 10 tablet: Each film coated tablet contains Dapagliflozin INN 10 mg.",
          ]
        },
        {
          innerHead: "PHARMACOLOGY:",
          innerData: "Dapazin® (Dapagliflozin) helps lower blood glucose levels by helping the body to filter more excess glucose out of the blood. Sodium-glucose co transporter 2 (SGLT2), expressed in the proximal renal tubules, is responsible for the majority of the re-absorption of filtered glucose from the tubular lumen. Dapagliflozin is an inhibitor of SGLT2. By inhibiting SGLT2, Dapagliflozin reduces reabsorption of filtered glucose and lowers the renal threshold for glucose, and thereby increases urinary glucose excretion.",
          istable: false,
          table: {},
          islist: false,
          listData:[
            "",
            "",
          ]
        },
        {
          innerHead: "INDICATIONS",
          innerData: "Dapazin® (Dapagliflozin) is indicated as an adjunct to diet and exercise to improve glycemic control in adults with type 2 diabetes mellitus.Limitation of Use: Dapagliflozin is not recommended for  patients with type 1 diabetes mellitus or for the treatment of diabetic ketoacidosis.",
          istable: false,
          table: {},
          islist: false,
          listData:[
            "",
            "",
          ]
        },
        
      ],
      innerdata1:[
        {
          innerHead: "DOSAGE & ADMINISTRATION",
          innerData: ``,
          istable: false,
          table: {},
          islist: true,
          listData:[
            "The recommended starting dose is 5 mg once daily, taken in the morning, with or without food.",
            "Dose can be increased to 10 mg once daily in patients who require additional glycemic control.",
            "Assess renal function before initiating Dapazin. Do not initiate Dapazin if eGFR is below 60 mL/min/1.73 m².",
            "Discontinue Dapazin if eGFR falls persistently below 60 mL/min/1.73 m².",
          ]
        },
        {
          innerHead: "SIDE EFFECTS",
          innerData: "Dapagliflozin may cause female genital mycotic infections, nasopharyngitis and urinary tract infections.",
          istable: false,
          table: {},
          islist: false,
          listData:[
            "",
            "",
          ]
        },
        {
          innerHead: "DRUG INTERACTIONS",
          innerData: ``,
          istable: false,
          table: {},
          islist: true,
          listData:[
            "Hypoglycemic Agents: May enhance the adverse/toxic effect of other hypoglycemic agents.",
            "Hypotensive Agents: May enhance the adverse/toxic effect of other hypotensive agents.",
            "Loop Diuretics: May diminish the hypoglycemic effect of hypoglycemic agents.",
            "MAO Inhibitors: May enhance the hypoglycemic effect of hypoglycemic agents.",
            "Selective Serotonin Reuptake Inhibitors:- May enhance the hypoglycemic effect of hypoglycemic agents.",
          ]
        },
        {
          innerHead: "USE IN PREGNANCY AND LACTATION:",
          innerData: "Pregnancy: There are no adequate well controlled studies in pregnant women. Use during pregnancy only if the potential benefit justifies the potential risk to fetus.Nursing mother: Discontinue Dapazin or discontinue nursing.",
          istable: false,
          table: {},
          islist: false,
          listData:[
            "",
            "",
          ]
        },
        {
          innerHead: "OVERDOSE",
          innerData: "There were no reports of overdose during the clinical development program for Dapagliflozin.",
          istable: false,
          table: {},
          islist: false,
          listData:[
            "",
            "",
          ]
        },
        {
          innerHead: "STORAGE",
          innerData: "Store in a cool (Below 30⁰ C. Temp.), dry place and protect from light. Keep all medicines out of the reach of children.",
          istable: false,
          table: {},
          islist: false,
          listData:[
            "",
            "",
          ]
        },
        {
          innerHead: "COMMERCIAL PACK",
          innerData: "Dapazin® 5 tablet: Each box containing 2 X 14’s tablet in Alu-Alu blister strips.Dapazin® 10 tablet: Each box containing 1 X 14’s tablet in Alu-Alu blister strips.",
          istable: false,
          table: {},
          islist: false,
          listData:[
            "",
            "",
          ]
        },
        {
          innerHead: "",
          innerData: ``,
          istable: false,
          table: {},
          islist: false,
          listData:[
            "",
            "",
          ]
        },
        {
          innerHead: "",
          innerData: ``,
          istable: false,
          table: {},
          islist: false,
          listData:[
            "",
            "",
          ]
        },
        
      ],
    btn: "Read More",
    backpagename: "First Time Launching",
    backpagelink: "/products_firsttime",
    btnlink: "/dapzine-tablet",
    type: 'Tablet'
  },

  {
    id: 4,
    title: "Dapazin 10 Tablet",
    text1: "Vitamin & Minerals",
    text2: "Dapagliflozin INN 10 mg",
    img: [
      {
        id: 4,
        imgf: dapazin10fr,
        imge: dapazin10bk,
      },
    ],
    innerMainHeading: "Dapazin 10",
      innerComposition: "Dapagliflozin INN",
      innerdata:[
        {
          innerHead: "PRESENTATION",
          innerData: ``,
          istable: false,
          table: {},
          islist: true,
          listData:[
            "Dapazin® 5 tablet: Each film coated tablet contains Dapagliflozin INN 5 mg.",
            "Dapazin® 10 tablet: Each film coated tablet contains Dapagliflozin INN 10 mg.",
          ]
        },
        {
          innerHead: "PHARMACOLOGY:",
          innerData: "Dapazin® (Dapagliflozin) helps lower blood glucose levels by helping the body to filter more excess glucose out of the blood. Sodium-glucose co transporter 2 (SGLT2), expressed in the proximal renal tubules, is responsible for the majority of the re-absorption of filtered glucose from the tubular lumen. Dapagliflozin is an inhibitor of SGLT2. By inhibiting SGLT2, Dapagliflozin reduces reabsorption of filtered glucose and lowers the renal threshold for glucose, and thereby increases urinary glucose excretion.",
          istable: false,
          table: {},
          islist: false,
          listData:[
            "",
            "",
          ]
        },
        {
          innerHead: "INDICATIONS",
          innerData: "Dapazin® (Dapagliflozin) is indicated as an adjunct to diet and exercise to improve glycemic control in adults with type 2 diabetes mellitus.Limitation of Use: Dapagliflozin is not recommended for  patients with type 1 diabetes mellitus or for the treatment of diabetic ketoacidosis.",
          istable: false,
          table: {},
          islist: false,
          listData:[
            "",
            "",
          ]
        },
        
      ],
      innerdata1:[
        {
          innerHead: "DOSAGE & ADMINISTRATION",
          innerData: ``,
          istable: false,
          table: {},
          islist: true,
          listData:[
            "The recommended starting dose is 5 mg once daily, taken in the morning, with or without food.",
            "Dose can be increased to 10 mg once daily in patients who require additional glycemic control.",
            "Assess renal function before initiating Dapazin. Do not initiate Dapazin if eGFR is below 60 mL/min/1.73 m².",
            "Discontinue Dapazin if eGFR falls persistently below 60 mL/min/1.73 m².",
          ]
        },
        {
          innerHead: "SIDE EFFECTS",
          innerData: "Dapagliflozin may cause female genital mycotic infections, nasopharyngitis and urinary tract infections.",
          istable: false,
          table: {},
          islist: false,
          listData:[
            "",
            "",
          ]
        },
        {
          innerHead: "DRUG INTERACTIONS",
          innerData: ``,
          istable: false,
          table: {},
          islist: true,
          listData:[
            "Hypoglycemic Agents: May enhance the adverse/toxic effect of other hypoglycemic agents.",
            "Hypotensive Agents: May enhance the adverse/toxic effect of other hypotensive agents.",
            "Loop Diuretics: May diminish the hypoglycemic effect of hypoglycemic agents.",
            "MAO Inhibitors: May enhance the hypoglycemic effect of hypoglycemic agents.",
            "Selective Serotonin Reuptake Inhibitors:- May enhance the hypoglycemic effect of hypoglycemic agents.",
          ]
        },
        {
          innerHead: "USE IN PREGNANCY AND LACTATION:",
          innerData: "Pregnancy: There are no adequate well controlled studies in pregnant women. Use during pregnancy only if the potential benefit justifies the potential risk to fetus.Nursing mother: Discontinue Dapazin or discontinue nursing.",
          istable: false,
          table: {},
          islist: false,
          listData:[
            "",
            "",
          ]
        },
        {
          innerHead: "OVERDOSE",
          innerData: "There were no reports of overdose during the clinical development program for Dapagliflozin.",
          istable: false,
          table: {},
          islist: false,
          listData:[
            "",
            "",
          ]
        },
        {
          innerHead: "STORAGE",
          innerData: "Store in a cool (Below 30⁰ C. Temp.), dry place and protect from light. Keep all medicines out of the reach of children.",
          istable: false,
          table: {},
          islist: false,
          listData:[
            "",
            "",
          ]
        },
        {
          innerHead: "COMMERCIAL PACK",
          innerData: "Dapazin® 5 tablet: Each box containing 2 X 14’s tablet in Alu-Alu blister strips.Dapazin® 10 tablet: Each box containing 1 X 14’s tablet in Alu-Alu blister strips.",
          istable: false,
          table: {},
          islist: false,
          listData:[
            "",
            "",
          ]
        },
        {
          innerHead: "",
          innerData: ``,
          istable: false,
          table: {},
          islist: false,
          listData:[
            "",
            "",
          ]
        },
        {
          innerHead: "",
          innerData: ``,
          istable: false,
          table: {},
          islist: false,
          listData:[
            "",
            "",
          ]
        },
        
      ],
    btn: "Read More",
    backpagename: "First Time Launching",
    backpagelink: "/products_firsttime",
    btnlink: "#",
    type: 'Tablet'
  },
  // Dapzin Cut End

  // {
  //   id: 5,
  //   title: "Dopagut 60 ml Syrup",
  //   text1: "Gastroprokinetic Agent",
  //   text2: "Domperidone 5 mg/ 5 ml",
  //   img: [
  //     {
  //       id: 5,
  //       imgf: dopagut60fr,
  //       imge: dopagut60bk,
  //     },
  //   ],
  //   innerMainHeading: "Dopagut",
  //     innerComposition: "Domperidone BP",
  //     innerdata:[
  //       {
  //         innerHead: "COMPOSITION",
  //         innerData: "Dopagut® Tablet: Each film coated tablet contains- Domperidone BP 10 mg as Domperidone Maleate BP. <br/>   Dopagut® Suspension: Each 5 ml Suspension contains- Domperidone BP 5 mg.",
  //         istable: false,
  //         table: {},
  //         islist: false,
  //         listData:[]
  //       },
  //       {
  //         innerHead: "PHARMACOLOGY",
  //         innerData: "Domperidone is a dopamine antagonist. It acts peripherally rather than central action; since it cannot readily enter the central nervous system due to blood brain barrier, its effects are confined to the periphery and acts principally at the receptor site in the chemoreceptor trigger zone.",
  //         istable: false,
  //         table: {},
  //         islist: false,
  //         listData:[]
  //       },
  //       {
  //         innerHead: "INDICATIONS",
  //         innerData: "Dyspeptic symptom complex, often associated with delayed gastric emptying, Gastroesophageal reflux and esophagitis: Epigastric sense of fullness, feeling of abdominal distension, upper abdominal pain Eructation, flatulence, early satiety, Nausea and vomiting, Heartburn with or without regurgitations of gastric contents in the mouth, Diabetic gastroparesis, Non-ulcer dyspepsia.<br/>   \tAcute nausea and vomiting of functional, organic, infectious, dietetic origin or induced by radiotherapy or drug therapy or induced in migraine.<br/>   \tParkinson's disease: In doparnine-agonist induced-nausea and vomiting.",
  //         istable: false,
  //         table: {},
  //         islist: false,
  //         listData:[]
  //       }
  //     ],
  //     innerdata1:[
  //       {
  //         innerHead: "DOSAGE AND ADMINISTRATION",
  //         innerData: "Dopagut® should be taken 15-30 minutes before meals and, if necessary, before retiring.   The usual recommended oral dose of Domperidone is as follow:   Adults: 10-20 mg (1-2 Dopagut® tablet or 10-20 ml Dopagut® suspension) every 4-8 hours daily.   Children: 2-4 ml Dopagut® suspension/10 kg every 4-8 hours daily.   In dyspeptic symptom:   Adults: 10-20 mg (1-2 Dopagut® tablet or 10-20 ml Dopagut® suspension), every 4-8 hours daily.   Children: 0.2-0.4 mg/kg (2-4 ml Dopagut® suspension/10 kg) body weight, every 4-8 hours daily.   In acute and sub-acute conditions (mainly in acute nausea and vomiting):   Adults: 20 mg (2 Dopagut® tablet or 20 ml Dopagut® suspension), every 4-8 hours.   Children: 0.2-0.4 mg/kg (2-4 ml Dopagut® suspension/10 kg) body weigh; every 4-8 hours daily. (In acute nausea and vomiting maximum period of treatment is 12 weeks).",
  //         istable: false,
  //         table: {},
  //         islist: false,
  //         listData:[]
  //       },
  //       {
  //         innerHead: "SIDE-EFFECTS",
  //         innerData: "Domperidone may produce hyperprolactinemia (1/3%). This may result in galactorrhea, breast enlargement, soreness and reduced libido. Dry mouth (1%), thirst, headache (1.2%), nervousness, drowsiness (0.4%), diarrhea (0.2%), skin rash and itching (0.1%) may occur during treatment with domperidone. Extrapyramidal reactions are seen in 0.05% of patients in clinical studies.",
  //         istable: false,
  //         table: {},
  //         islist: false,
  //         listData:[]
  //       },
  //       {
  //         innerHead: "PRECAUTIONS",
  //         innerData: "Domperidone should be used with absolute caution in case of children because there may be increased risk of extra-pyramidal reactions in young children because of an incompletely developed blood-brain barrier. Since domperidone is highly metabolized in liver, it should be used with caution in patient with hepatic impairment.",
  //         istable: false,
  //         table: {},
  //         islist: false,
  //         listData:[]
  //       },
  //       {
  //         innerHead: "CONTRAINDICATION",
  //         innerData: "Domperidone is contraindicated to patients having known hypersensitivity to this drug and in case of neonates. Domperidone should not be used whenever gastro-intestinal stimulation might be dangerous i.e., gastrointestinal hemorrhage, mechanical obstruction or perforation. It is also contraindicated in patients with prolactin releasing pituitary tumor (prolactinoma).",
  //         istable: false,
  //         table: {},
  //         islist: false,
  //         listData:[]
  //       },
  //       {
  //         innerHead: "USE IN PREGNANCY AND LACTATIO",
  //         innerData: "  Pregnant woman: The safety of domperidone has not been proven and it is therefore not recommended during pregnancy. Animal studies have not demonstrated teratogenic effect in the fetus.     Lactating mother: Domperidone may precipitate galactorrhea and improve postnatal lactation. It is secreted in breast milk but in very small quantities insufficient to be considered harmful.",
  //         istable: false,
  //         table: {},
  //         islist: false,
  //         listData:[]
  //       },
  //       {
  //         innerHead: "DRUG INTERACTIONS",
  //         innerData: "Apuldon may reduce the hypoprolactinemic effect of bromocriptine. The effect of antimuscarinics & opioid analgesics may antagonize Apuldon on GI function.",
  //         istable: false,
  //         table: {},
  //         islist: false,
  //         listData:[]
  //       },
  //       {
  //         innerHead: "OVERDOSE",
  //         innerData: "There is no specific antidote to domperidone, but in the event of overdose, standard symptomatic treatment should be given immediately. Gastric lavage as well as the administration of activated charcoal, may be useful. ECG monitoring should be undertaken, because of the possibility of QT interval prolongation. Close medical supervision and supportive therapy is recommended. Anticholinergic, anti-parkinson drugs may be helpful in controlling the extra pyramidal reactions.",
  //         istable: false,
  //         table: {},
  //         islist: false,
  //         listData:[]
  //       },
  //       {
  //         innerHead: "COMMERCIAL PACK",
  //         innerData: ``,
  //         istable: false,
  //         table: {},
  //         islist: true,
  //         listData: [
  //         "Dopagut® Tablet: Box containing 10x10’s tablets, in blister strips.",
  //         "Dopagut® Suspension: Bottle containing 60 ml suspension.",
  //       ],
  //       },
  //     ],
  //   btn: "Read More",
  //   backpagename: "First Time Launching",
  //   backpagelink: "/products_firsttime",
  //   btnlink: "#",
  //   type: 'Syrup'
  // },

  // Relikof Cut
  {
    id: 6,
    title: "Relikof 2.5 Syrup",
    text1: "Citric Acid Monohydrate BP",
    text2: "31.25 mg/ 5 ml",
    img: [
      {
        id: 6,
        imgf: relikof25fr,
        imge: relikof25bk,
      },
    ],
    innerMainHeading: "Relikof® Kidz Linctus",
      innerComposition: "Citric Acid Monohydrate BP",
      innerdata:[
        {
          innerHead: "COMPOSITION",
          innerData: `Relikof® Kidz Linctus: Each 5 ml Linctus contains Citric Acid Monohydrate BP 31.25 mg.`,
          istable: false,
          table: {},
          islist: false,
          listData:[
            "",
            "",
          ]
        },
        {
          innerHead: "PHARMACOLOGY",
          innerData: `Relikof® kidz linctus contains Citric Acid Monohydrate BP which is moderately transparent, slightly yellowish, natural Anise flavored sugar free Linctus. It acts as demulent& forms a protective film on surface of respiratory mucus membrane. This Relikof® Kidz Linctus provides a soothing effect on throat relieving irritating dry cough.`,
          istable: false,
          table: {},
          islist: false,
          listData:[
            "",
            "",
          ]
        },
        {
          innerHead: "INDICATION",
          innerData: `Relikof® linctus is indicated for relief of all types of non productive (dry) coughs, specially-`,
          istable: false,
          table: {},
          islist: true,
          listData:[
            "Environment induced dry cough",
            "Cold induced dry cough",
            "Irritating dry cough",
            "Allergic dry cough",
          ]
        },
        
      ],
      innerdata1:[
        {
          innerHead: "DOSAGE AND ADMINISTRATION",
          innerData: `Child (1 month-12 Years): Relikof® Kidz Linctus 5-10 ml (1-2 teaspoon), 3-4 times daily.`,
          istable: false,
          table: {},
          islist: false,
          listData:[
            "",
            "",
          ]
        },
        {
          innerHead: "SIDE-EFFECTS",
          innerData: `There are no known side effects from using this medicine. However, if you notice any side effects, stop use and consult a doctor or pharmacist.`,
          istable: false,
          table: {},
          islist: false,
          listData:[
            "",
            "",
          ]
        },
        {
          innerHead: "CONTRAINDICATIONS",
          innerData: `Contraindicated in patients having hypersensitivity to any of the ingredients of Citric Acid Monohydrate .`,
          istable: false,
          table: {},
          islist: false,
          listData:[
            "",
            "",
          ]
        },
        {
          innerHead: "PRECAUTIONS",
          innerData: `Consult a doctor if cough symptoms persist for more than 5 days.`,
          istable: false,
          table: {},
          islist: false,
          listData:[
            "",
            "",
          ]
        },
        {
          innerHead: "USE IN PREGNANCY AND LACTATION",
          innerData: `There are no enough data from the use of Citric Acid Monohydrate in pregnant women. There is insufficient information on the excretion of Citric Acid Monohydrate metabolites in Human milk.  If necessary, it can used with the consultation of physician.`,
          istable: false,
          table: {},
          islist: false,
          listData:[
            "",
            "",
          ]
        },
        {
          innerHead: "DRUG INTERACTION",
          innerData: `No clinically significant interactions have found.`,
          istable: false,
          table: {},
          islist: false,
          listData:[
            "",
            "",
          ]
        },
        {
          innerHead: "OVERDOSE",
          innerData: `Overdose with this preparation is unlikely to occur due to the low concentrations of the ingredients.`,
          istable: false,
          table: {},
          islist: false,
          listData:[
            "",
            "",
          ]
        },
        {
          innerHead: "STORAGE",
          innerData: `Store in a cool (Below 30⁰C Temp.) & dry place, protect from light. Keep out of the reach of children.`,
          istable: false,
          table: {},
          islist: false,
          listData:[
            "",
            "",
          ]
        },
        {
          innerHead: "COMMERCIAL PACK",
          innerData: `Relikof® Kidz Linctus: Each bottle contains 100 ml linctus with a measuring cup.`,
          istable: false,
          table: {},
          islist: false,
          listData:[
            "",
            "",
          ]
        },
        
      ],
    btn: "Read More",
    backpagename: "First Time Launching",
    backpagelink: "/products_firsttime",
    btnlink: "/relikof-syrup",
    type: 'Syrup'
  },


  {
    id: 7,
    title: "Relikof Kidz Syrup",
    text1: "Citric Acid Monohydrate BP",
    text2: "125 mg/ 5 ml",
    img: [
      {
        id: 7,
        imgf: relikofkidzfr,
        imge: relikofkidzbk,
      },
    ],
    innerMainHeading: "Relikof® Kidz Linctus",
      innerComposition: "Citric Acid Monohydrate BP",
      innerdata:[
        {
          innerHead: "COMPOSITION",
          innerData: `Relikof® Kidz Linctus: Each 5 ml Linctus contains Citric Acid Monohydrate BP 31.25 mg.`,
          istable: false,
          table: {},
          islist: false,
          listData:[
            "",
            "",
          ]
        },
        {
          innerHead: "PHARMACOLOGY",
          innerData: `Relikof® kidz linctus contains Citric Acid Monohydrate BP which is moderately transparent, slightly yellowish, natural Anise flavored sugar free Linctus. It acts as demulent& forms a protective film on surface of respiratory mucus membrane. This Relikof® Kidz Linctus provides a soothing effect on throat relieving irritating dry cough.`,
          istable: false,
          table: {},
          islist: false,
          listData:[
            "",
            "",
          ]
        },
        {
          innerHead: "INDICATION",
          innerData: `Relikof® linctus is indicated for relief of all types of non productive (dry) coughs, specially-`,
          istable: false,
          table: {},
          islist: true,
          listData:[
            "Environment induced dry cough",
            "Cold induced dry cough",
            "Irritating dry cough",
            "Allergic dry cough",
          ]
        },
        
      ],
      innerdata1:[
        {
          innerHead: "DOSAGE AND ADMINISTRATION",
          innerData: `Child (1 month-12 Years): Relikof® Kidz Linctus 5-10 ml (1-2 teaspoon), 3-4 times daily.`,
          istable: false,
          table: {},
          islist: false,
          listData:[
            "",
            "",
          ]
        },
        {
          innerHead: "SIDE-EFFECTS",
          innerData: `There are no known side effects from using this medicine. However, if you notice any side effects, stop use and consult a doctor or pharmacist.`,
          istable: false,
          table: {},
          islist: false,
          listData:[
            "",
            "",
          ]
        },
        {
          innerHead: "CONTRAINDICATIONS",
          innerData: `Contraindicated in patients having hypersensitivity to any of the ingredients of Citric Acid Monohydrate .`,
          istable: false,
          table: {},
          islist: false,
          listData:[
            "",
            "",
          ]
        },
        {
          innerHead: "PRECAUTIONS",
          innerData: `Consult a doctor if cough symptoms persist for more than 5 days.`,
          istable: false,
          table: {},
          islist: false,
          listData:[
            "",
            "",
          ]
        },
        {
          innerHead: "USE IN PREGNANCY AND LACTATION",
          innerData: `There are no enough data from the use of Citric Acid Monohydrate in pregnant women. There is insufficient information on the excretion of Citric Acid Monohydrate metabolites in Human milk.  If necessary, it can used with the consultation of physician.`,
          istable: false,
          table: {},
          islist: false,
          listData:[
            "",
            "",
          ]
        },
        {
          innerHead: "DRUG INTERACTION",
          innerData: `No clinically significant interactions have found.`,
          istable: false,
          table: {},
          islist: false,
          listData:[
            "",
            "",
          ]
        },
        {
          innerHead: "OVERDOSE",
          innerData: `Overdose with this preparation is unlikely to occur due to the low concentrations of the ingredients.`,
          istable: false,
          table: {},
          islist: false,
          listData:[
            "",
            "",
          ]
        },
        {
          innerHead: "STORAGE",
          innerData: `Store in a cool (Below 30⁰C Temp.) & dry place, protect from light. Keep out of the reach of children.`,
          istable: false,
          table: {},
          islist: false,
          listData:[
            "",
            "",
          ]
        },
        {
          innerHead: "COMMERCIAL PACK",
          innerData: `Relikof® Kidz Linctus: Each bottle contains 100 ml linctus with a measuring cup.`,
          istable: false,
          table: {},
          islist: false,
          listData:[
            "",
            "",
          ]
        },
        
      ],
    btn: "Read More",
    backpagename: "First Time Launching",
    backpagelink: "/products_firsttime",
    btnlink: "/relikof-kids-syrup",
    type: 'Syrup'
  },
// Relikof Cut End

  // {
  //   id: 8,
  //   title: "Unilor Tablet",
  //   text1: "Anti-histamine",
  //   text2: "Desloratadine 5 mg",
  //   img: [
  //     {
  //       id: 8,
  //       imgf: unilorfr,
  //       imge: unilorbk,
  //     },
  //   ],
  //   innerMainHeading: "Unilor",
  //     innerComposition: "Desloratadine INN",
  //     innerdata:[
  //       {
  //         innerHead: "COMPOSITION",
  //         innerData: ``,
  //         istable: false,
  //         table: {},
  //         islist: true,
  //         listData:[
  //           "Unilor tablet: Each film coated tablet contains Desloratadine INN 5 mg.",
  //           "Unilor syrup: Each 5 ml contains Desloratadine INN 2.5mg",
  //         ]
  //       },
  //       {
  //         innerHead: "DESCRIPTION",
  //         innerData: `Desloratadine is a long-acting tricyclic histamine antagonist with selective H1-receptor antagonist activity. It is a non-sedating antihistamine. Desloratadine has effects on the chronic inflammatory response to allergens.    `,
  //         istable: false,
  //         table: {},
  //         islist: false,
  //         listData:[
  //           "",
  //           "",
  //         ]
  //       },
  //       {
  //         innerHead: "INDICATIONS",
  //         innerData: `Allergic rhinitis: Desloratadine is indicated for the relief of nasal and non-nasal symptoms of allergic rhinitis (seasonal and perennial). 
  //         Chronic idiopathic urticaria: Desloratadine is indicated for the symptomatic relief of pruritus, reduction in the number of hives and size of hives, in patients with chronic idiopathic urticaria.
  //         `,
  //         istable: false,
  //         table: {},
  //         islist: false,
  //         listData:[
  //           "",
  //           "",
  //         ]
  //       },
  //     ],
  //     innerdata1:[
  //       {
  //         innerHead: "DOSAGE AND ADMINISTRATION",
  //         innerData: `Unilor Syrup: Child 2-5 years of Age: 1/2 teaspoonful once daily
  //         Child 6-11 years of Age: 1 teaspoonful once daily
  //         Adults &> years of Age: 2 teaspoonful once daily
  //         Unilor Tablet: Adults and children 12 years of age and over: 1 Tablet daily.
  //         `,
  //         istable: false,
  //         table: {},
  //         islist: false,
  //         listData:[
  //           "",
  //           "",
  //         ]
  //       },
  //       {
  //         innerHead: "USE IN PREGNANCY AND LACTATION",
  //         innerData: `Pregnancy: There are no adequate and well-controlled studies in pregnant women.  Because animal reproduction studies are not always predictive of human response, Desloratadine should be used in pregnancy only if clearly needed.
  //         Lactation: Desloratadine passes into breast milk. Therefore, a decision should be made whether to discontinue nursing or to discontinue Desloratadine, taking into account the importance of the drug to the mother.
  //         SIDE EFFECTS
  //         `,
  //         istable: false,
  //         table: {},
  //         islist: false,
  //         listData:[
  //           "",
  //           "",
  //         ]
  //       },
  //       {
  //         innerHead: "SIDE EFFECTS",
  //         innerData: `Desloratadine is generally well tolerated. However, dry mouth, fatigue, somnolence and myalgia are commonly reported side-effects. Less common side-effects may include dizziness, headache and nausea. Rarely rash, pruritus and urticaria may occur.`,
  //         istable: false,
  //         table: {},
  //         islist: false,
  //         listData:[
  //           "",
  //           "",
  //         ]
  //       },
  //       {
  //         innerHead: "CONTRAINDICATIONS",
  //         innerData: `Desloratadine is contraindicated in patients who are hypersensitive to this medication or to any of its ingredients or to Loratadine.     `,
  //         istable: false,
  //         table: {},
  //         islist: false,
  //         listData:[
  //           "",
  //           "",
  //         ]
  //       },
  //       {
  //         innerHead: "DRUG INTERACTIONS",
  //         innerData: `Alcohol enhances the sedative effect of Desloratadine. Erythromycin, Ketoconazole, Cimetidine & Amprenavir may increase plasma concentration of Loratadine.  `,
  //         istable: false,
  //         table: {},
  //         islist: false,
  //         listData:[
  //           "",
  //           "",
  //         ]
  //       },
  //       {
  //         innerHead: "SPECIAL PRECAUTIONS FOR USE",
  //         innerData: `Antihistamine should be used with caution in prostatic hypertrophy, urinary retention, glaucoma, and pyloroduodenal obstruction. It should be used with caution in hepatic disease and dose reduction may be necessary in the renal impairment. Caution may be required in epilepsy and porphyria. Children and the elderly are more susceptible to side effects.`,
  //         istable: false,
  //         table: {},
  //         islist: false,
  //         listData:[
  //           "",
  //           "",
  //         ]
  //       },
  //       {
  //         innerHead: "OVERDOSAGE",
  //         innerData: `No clinically relevant adverse effects have been reported in case of over dosage. However, in the event of overdosage, symptomatic and supportive treatment is recommended.`,
  //         istable: false,
  //         table: {},
  //         islist: false,
  //         listData:[
  //           "",
  //           "",
  //         ]
  //       },
  //       {
  //         innerHead: "STORAGE",
  //         innerData: `Store in a cool (Below 30⁰ C. Temp.), dry place and protect from light. Keep all medicines out of the reach `,
  //         istable: false,
  //         table: {},
  //         islist: false,
  //         listData:[
  //           "",
  //           "",
  //         ]
  //       },
  //       {
  //         innerHead: "COMMERCIAL PACK",
  //         innerData: ``,
  //         istable: false,
  //         table: {},
  //         islist: true,
  //         listData:[
  //           "Unilor tablet: Each box containing 50’s tablets in blister strips.",
  //           "Unilor syrup: Each bottle containing 60 ml.",
  //         ]
  //       },
  //       {
  //         innerHead: "",
  //         innerData: ``,
  //         istable: false,
  //         table: {},
  //         islist: false,
  //         listData:[
  //           "",
  //           "",
  //         ]
  //       },
  //       {
  //         innerHead: "",
  //         innerData: ``,
  //         istable: false,
  //         table: {},
  //         islist: false,
  //         listData:[
  //           "",
  //           "",
  //         ]
  //       },
  //       {
  //         innerHead: "",
  //         innerData: ``,
  //         istable: false,
  //         table: {},
  //         islist: false,
  //         listData:[
  //           "",
  //           "",
  //         ]
  //       },
        
  //     ],
  //   btn: "Read More",
  //   backpagename: "First Time Launching",
  //   backpagelink: "/products_firsttime",
  //   btnlink: "#",
  //   type: 'Tablet'
  // },

]
import React from 'react'

const TeleMedicine = () => {
    return (
        <div>
          <h1>TeleMedicine</h1>  
        </div>
    )
}

export default TeleMedicine

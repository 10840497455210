import React from 'react'

const Media_socialresponsiblities = () => {
    return (
        <div>
            Media_socialresponsiblities
        </div>
    )
}

export default Media_socialresponsiblities

import React from "react";
import "./TextSectionContainer.css";
const TextSectionContainer = () => {
  return (
    <div className="container container_inside">
        <div className="row">
          <div className="col-11 p-4 text_shadow text-center ">
              <p className="text_typography">
                CONCORD Pharmaceuticals Ltd., a fast growing pharmaceutical
                company is committed to manufacture quality medicines through
                strict adherence to internationally accepted standards of Good
                Manufacturing Practice with a motto of "Better Medicine Better
                Life ... ". CONCORD Pharmaceuticals is a sister concern of
                country's one of the leading business conglomerate NOA-PARA
                GROUP which is involved in local & international Trading,
                Fertilizer, Cement, Coal & many other multiple diversified
                business sectors. The factory of CONCORD Pharmaceuticals Ltd.
                  (CPL) is situated at Kanchpur, BSCIC in Narayangonj district
                  and 15 km far from Dhaka city. The company produces various
                  types of drugs which include tablets, capsules, oral liquids
                  etc. Since its beginning, CONCORD has launched many new and
                  innovative products in order to fulfill unmet demand of the
                  medical community. Production facilities have built up the
                  integrated system of Research and Development, Quality Control
                  & Assurance to fulfill the medicine need of population of
                  Bangladesh and all over the world.
              </p>
          </div>
        </div>
    </div>
  );
};

export default TextSectionContainer;
